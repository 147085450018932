import * as React from 'react';
import {
  Avatar,
  Box, Button,
  Card,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import { adminListFunction, adminUpdateCompany, adminUpdateUser } from '../../../firebase/admin';
import PageContainer from '../../../components/container/PageContainer';
import { TablePaginationActions } from '../../../components/table/pagination';
import AdminCompanyEditPage from './edit';
import AdminNewCompany from './new';

const AdminCompaniesPage = () => {
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rowLength, setRowLength] = React.useState(0);

  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [newCompany, setNewCompany] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    adminListFunction('adminListCompanies', page, rowsPerPage).then(({ list, totalSize }) => {
      setRows(list || []);
      setRowLength(totalSize || 0);
      setLoading(false);
    }).catch((err) => {
      console.error(err);
      setLoading(false);
    });
  }, [page, rowsPerPage]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowLength) : 0;

  const onClosEdit = async ({ id, editUser, editCompany, action }) => {
    if (action === 'submit') {
      setSaving(true);
      try {
        // update user info in db
        const response = await adminUpdateCompany(id, { userData: editUser, companyData: editCompany });
        // success: false, message: 'Username already taken'
        if (response?.data?.success === false) {
          alert(response?.data?.message || "An error occurred");
          // console.log(response);
          setSaving(false);
          return false;
        }
      } catch (e) {
        alert(e);
        console.log(e);
        setSaving(false);
        return false;
      }

      // process all key values from editState
      setRows((prev) => {
        const newRows = prev.map((row) => {
          if (row.user.id === id) {
            return {
              ...row,
              user: {
                ...row.user,
                ...editUser,
              },
              company: {
                ...row.company,
                ...editCompany,
              },
            };
          }
          return row;
        });
        return newRows;
      });
    }

    if (action !== 'update') {
      setSelectedCompany(null);
    }
  };

  if (selectedCompany) {
    return (
      <AdminCompanyEditPage company={selectedCompany} onClose={onClosEdit} />
    );
  }

  if (newCompany) {
    return (
      <AdminNewCompany onClose={({ action, userData, companyData }) => {
        if (action === 'submit') {
          // prepend new company to rows list
          setRows((prev) => {
            return [
              {
                ...companyData,
                user: userData,
              },
              ...prev,
            ];
          });
        }
        setNewCompany(false)
      }} />
    )
  }

  console.log('rows', rows);

  return (
    <PageContainer title='Admin Companies' description='Admin Companies page'>
      <Card>
        <CardContent>
          <Box
            sx={{
              overflow: {
                xs: 'auto',
                md: 'unset',
              },
            }}
          >
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant='contained'
                color='primary'
                onClick={async (e) => {
                  e.preventDefault();
                  setNewCompany(true);
                }}
              >
                + New Company
              </Button>
            </Box>
            <Table
              aria-label='custom pagination table'
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h5'>Company Name</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              {loading
              ?
                <CircularProgress />
              :
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.user?.id}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedCompany(row);
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <TableCell>
                        <Box display='flex' alignItems='center'>
                          <Avatar
                            src={row.user?.profilePictureURL}
                            alt={row.user?.profilePictureURL}
                            width='30'
                            sx={{
                              borderRadius: '100%',
                            }}
                          />
                          <Box
                            sx={{
                              ml: 2,
                            }}
                          >
                            <Typography variant='h6' fontWeight='600'>
                              {row.user?.firstName}
                            </Typography>
                            <Typography variant='caption'>
                              {row.user?.email}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              }
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={6}
                    count={rowLength}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputprops: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(event) => {
                      setRowsPerPage(parseInt(event.target.value, 10));
                      setPage(0);
                    }}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default AdminCompaniesPage;
